<template>
  <div class="childBox">
    <div class="topSearch" @click="search()">
      <div>
        <img src="../image/schoolSearch.png" alt />
      </div>
      <div>搜索</div>
    </div>
    <div class="tabBox">
      <div class="tabDiv">
        <van-tabs v-model="tabActive" swipeable @click="tabEvent" id="tabs">
          <van-tab
            v-for="item in this.$store.state.tabList"
            :title="item.value"
            :key="item.id"
          ></van-tab>
        </van-tabs>
      </div>
      <div class="tabImg" @click="tabShow = true"></div>
    </div>

    <div class="contentTitleTime" v-for="i in lookList" :key="i.id">
      <div class="contentLeft">
        <div class="contentTitle" @click="detailEvent(i)">
          <span v-if="timeShow === '刚刚'">最新</span>
          {{ i.title }}
        </div>
        <div class="contentTime">{{ i.timeShow }}</div>
      </div>
      <div class="contentRight">
        <img :src="i.img" alt />
      </div>
    </div>
    <!-- 频道目录 -->
    <van-popup
      v-model="tabShow"
      position="bottom"
      round
      :style="{ height: '90%' }"
    >
      <div class="delImgTabShow">
        <img @click="tabShow = false" src="../image/delImg.png" alt />
      </div>
      <div class="tabShowTitle">
        <div>频道目录</div>
        <div>点击进入频道</div>
        <!-- <div>编辑</div> -->
      </div>
      <div class="tabShowList">
        <div
          v-for="(item, index) in this.$store.state.tabList"
          :key="item.id"
          :class="tabShowActive === item.id ? 'tabShowactive' : ''"
          @click="tabShowEvent(item, index)"
        >
          {{ item.value }}
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { infoPage } from "../api/lookAt";
export default {
  props: ["schoolId"],
  data() {
    return {
      tabActive: "",
      tabShow: false, //频道目录弹框
      tabShowActive: "", //频道目录弹框中的列表状态
      lookData: {
        pageNum: 1,
        pageSize: 10,
        content: "", //搜索
        provinceId: "", //省级id
        cityId: "", //市级id
        period: "", //学段id
        classify: "", //tabId
        schoolId: "", //学校id
      },
      lookList: [],
    };
  },
  mounted() {
    console.log(this.$store.state.tabList);
    console.log(this.schoolId, "子组件");
    this.lookData.schoolId = this.schoolId;

    if (this.$store.state.tabList[0].key === "0") {
      this.lookData.classify = "";
    } else {
      this.lookData.classify = this.$store.state.tabList[0].key;
    }
    this.gerinfoPage();
  },
  methods: {
    gerinfoPage() {
      infoPage(this.lookData).then((res) => {
        console.log(res, "资讯列表");
        this.lookList = res.data.data.items;
      });
    },
    // 导航点击事件
    tabEvent(item, index) {
      // console.log(item, index);
      this.tabActive = item;
      this.$store.state.tabList.forEach((i) => {
        if (i.value == index) {
          console.log(i.id, "-----");
          if (i.key === "0") {
            this.lookData.classify = "";
          } else {
            this.lookData.classify = i.key;
          }
          this.tabShowActive = i.id;
          this.gerinfoPage();
        }
      });
    },
    // 导航点击事件
    tabShowEvent(item, index) {
      console.log(item);
      if (item.key === "0") {
        this.lookData.classify = "";
      } else {
        this.lookData.classify = item.key;
      }
      this.tabShowActive = item.id;
      this.tabActive = index;
      this.tabShow = false;
      this.gerinfoPage();
    },
    // 跳转资讯详情
    detailEvent(item) {
      this.$router.push({ path: "/lookDetail", query: { item: item } });
      this.$store.commit("item", item);
    },
    search() {},
  },
};
</script>

<style scoped>
.topSearch {
  width: 686px;
  height: 64px;
  background: #f7f8fa;
  border-radius: 1272px 1272px 1272px 1272px;
  opacity: 1;
  margin-top: 32px;
  margin-left: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.topSearch div {
  display: flex;
  align-items: center;
}
.topSearch div:nth-child(1) img {
  width: 28px;
  height: 28px;
  background-image: url("../image/schoolSearch.png");
  background-size: 100% 100%;
  margin-right: 8px;
}
.topSearch div:nth-child(2) {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  font-size: 28px;
  margin-left: 8px;
}
/deep/#tabs .van-tabs__nav {
  background-color: transparent;
}
/deep/#tabs .van-tab--active {
  color: rgba(0, 0, 0, 0.9) !important;
  font-size: 28px !important;
}
/deep/#tabs .van-tab {
  color: rgba(0, 0, 0, 0.4);
}
/deep/#tabs .van-tabs__line {
  background-color: transparent !important;
}
/deep/ #tabs.van-tabs--line .van-tabs__wrap {
  border: none;
  margin-left: 16px !important;
}
/deep/ #tabs .van-tab__text {
  font-size: 28px !important;
}
.tabBox {
  position: relative;
}
.tabImg {
  width: 32px;
  height: 32px;
  background: #fff;
  background-image: url("../image/tabImg1.png");
  background-size: 100% 100%;
  position: absolute;
  top: 25px;
  right: 32px;
}
.delImgTabShow img {
  width: 32px;
  height: 32px;
  margin-left: 32px;
}
.tabShowTitle {
  display: flex;
  align-items: center;
}
.tabShowTitle div:nth-child(1) {
  font-size: 32px;
  font-weight: 500;
  color: #000000;
  margin-left: 32px;
}
.tabShowTitle div:nth-child(2) {
  font-size: 24px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  margin-left: 32px;
}
.tabShowTitle div:nth-child(3) {
  font-size: 28px;
  font-weight: 500;
  color: #5777ed;
  margin-left: 326px;
}
.tabShowList {
  display: flex;
  flex-wrap: wrap;
  font-size: 28px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
  margin-top: 48px;
  margin-left: 6px;
}
.tabShowList div {
  width: 152px;
  height: 74px;
  background: #f7f8fa;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  margin-bottom: 26px;
  text-align: center;
  line-height: 74px;
  margin-left: 26px;
}
.tabShowactive {
  color: #5777ed;
}
.contentTitleTime {
  display: flex;
  margin-top: 48px;
}
.contentLeft {
  margin-left: 32px;
}
.contentTitle {
  width: 450px;
  font-size: 32px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.contentTitle span {
  display: inline-block;
  width: 64px;
  height: 44px;
  background: rgba(69, 103, 230, 0.1);
  border-radius: 8px 8px 8px 8px;
  font-size: 24px;
  font-weight: 400;
  color: #0957c3;
  text-align: center;
  line-height: 44px;
  margin-right: 16px;
}
.contentTime {
  font-size: 24px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.26);
  margin-top: 24px;
}
.contentRight {
  width: 204px;
  height: 136px;
  margin-left: 32px;
}
.contentRight img {
  width: 204px;
  height: 136px;
  border-radius: 8px;
}
</style>
