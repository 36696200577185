<template>
  <div class="schoolDetailBox">
    <div class="topBack" :style="{ backgroundImage: `url(${dataObj.logo})` }">
      <div class="back">
        <img @click="goBack()" src="../../image/bbba.png" alt />
      </div>
    </div>
    <div class="containerDiv">
      <div class="hearSchoolTitle">
        <div>
          <img :src="dataObj.logo" alt />
        </div>
        <div>{{ dataObj.name }}</div>
      </div>
      <div class="size">{{ dataObj.title }}</div>
      <div class="tabDiv">
        <van-tabs v-model="$store.state.active" id="tab">
          <van-tab title="简介">
            <div class="brief" v-html="dataObj.desc"></div>
          </van-tab>
          <van-tab :title="title">
            <articlePage :schoolId="id"></articlePage>
          </van-tab>
        </van-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { schoolDetail } from "../../api/lookAt";
import { Tab, Tabs } from "vant";
import articlePage from "../../components/article";
export default {
  components: {
    articlePage,
  },
  data() {
    return {
      id: this.$store.state.schoolItem.id,
      dataObj: {},
      active: this.$store.state.active,
      title: "文章",
    };
  },
  mounted() {
    console.log(this.$store.state.schoolItem, "]]]");
    // this.$store.commit("active", this.active);
    schoolDetail({ id: this.id }).then((res) => {
      console.log(res, "名校详情");
      this.dataObj = res.data.data;
      this.title = this.title + " · " + res.data.data.infoCount;
      console.log(this.title);
    });
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.topBack {
  width: 750px;
  height: 334px;
  /* background-image: url("../../image/mxxqBack.png"); */
  background-size: 100% 100%;
  /* opacity: 0.5; */
  /* filter: blur(5px); */
}
.back img {
  width: 48px;
  height: 48px;
  margin-left: 32px;
}
.containerDiv {
  width: 750px;
  /* height: 1638px; */
  background: #ffffff;
  border-radius: 32px 32px 0px 0px;
  opacity: 1;
  margin-top: -80px;
  box-shadow: 0px -36px 30px 0px rgba(0, 0, 0, 0.3);
}
.hearSchoolTitle {
  display: flex;
}
.hearSchoolTitle div:nth-child(1) {
  width: 160px;
  height: 160px;
  margin-top: -50px;
  margin-left: 32px;
}
.hearSchoolTitle div:nth-child(1) img {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  border: 2px solid #fff;
}
.hearSchoolTitle div:nth-child(2) {
  font-size: 32px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  margin-top: 32px;
  margin-left: 16px;
}
.size {
  font-size: 24px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  margin-left: 32px;
  margin-top: 16px;
  padding-right: 32px;
  line-height: 32px;
}
.tabDiv {
  margin-top: 32px;
}
/deep/#tab .van-tabs__line {
  background: #0957c3;
  width: 16px;
  height: 4px;
}
/deep/#tab .van-tab--active {
  font-size: 32px;
  font-weight: 500;
  color: #0957c3;
}
/deep/.van-tab {
  font-size: 32px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
}
/deep/.van-tabs--line .van-tabs__wrap {
  width: 686px;

  border-bottom: 2px solid #ebedf0;
  margin-left: 32px;
}
.brief {
  width: 686px;
  font-size: 32px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
  margin-left: 32px;
  margin-top: 32px;
  margin-bottom: 50px;
  line-height: 60px;
}
</style>
